import React,{useState, useEffect} from 'react'
import '../Pages/style.css'
import sabreicon from "../assets/sabre.png"
import { FaSignOutAlt } from "react-icons/fa";
import { FiLogIn } from "react-icons/fi";
import {Link} from 'react-router-dom'

const Navbar = ({currentpage, loginClick, defaultState, logoutClick}) => {
  const [clicked, setClicked] = useState(true)
  const [loutclicked, setLoutClicked] = useState(true)
  const [entered, setEntered] = useState("0")
  const [currpage, setCurrpage] = useState(0)
  
  useEffect(()=>{
    checkCurrentState();
  })

  const checkCurrentState = () =>{
    // console.log(`default State: ${defaultState}`)
    setEntered(defaultState)
    setCurrpage(currentpage)
  }
  
  const loginClicked = () =>{
    setClicked(!clicked)
    loginClick(clicked)
  }

  const logoutClicked = () =>{
    setLoutClicked(!loutclicked)
    logoutClick(loutclicked)
  } 

  return (
    <div className='navbarContian'>
        <Link to="/" className='navbar-icon'><div id='imgIcon'/></Link>
        {currpage === 1 ? <Link to="/login" id='loginclick' onClick={loginClicked}>LOGIN</Link> : currpage === 3 ? <Link to="/login" id='loginclick' onClick={loginClicked}>LOGOUT</Link> : <></>}

        {entered === "1" ? <FiLogIn id='loginclick_' onClick={loginClicked}/> :  entered === "2" ? <FaSignOutAlt id='loginclick_' onClick={logoutClicked}/>  :  ""}
    </div>
  )
}

export default Navbar
import React from 'react'
import { FaCertificate } from 'react-icons/fa'
import { GiGraduateCap } from 'react-icons/gi'
import { MdFreeCancellation } from 'react-icons/md'

import './pointCard.css'

const PointCards = ({title, icon, baccolor, ccolor, text}) => {
  const Iconobj = {
    1 : <GiGraduateCap id='gradIcon'/>,
    2 : <MdFreeCancellation id='nongradIcon'/>,
    3 : <FaCertificate id='paidIcon'/>
  }

  return (
    <div className='pointcard-container' style={{ background: baccolor}}>
      <div className='circle1'/>
      <div className='circle2'/>
      <div className='circle3'/>

        <div className='pcrd-row'>
            <span id='pcrd-icons'>{Iconobj[icon]}</span>
        </div>

        <div className='pcrd-row2'>
            <span style={{ color: ccolor}}>{title}</span>
        </div>
        
        <div className='pcrd-row1'>
            <span style={{ color: ccolor}}>{text}</span>
        </div>
    </div>
  )
}

export default PointCards
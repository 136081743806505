import React, {useState, useEffect} from 'react'
import './style.css'
import axios from 'axios'
import Error from '../Component/Error';
import Footer from './Footer';
import { FaCloudDownloadAlt, FaSignInAlt } from "react-icons/fa";
import Certif from '../assets/Certificate.svg'
// import SLoader from '../Component/Loading/SigninLoader';
// import Confirm from '../Component/Confirm';
import { useNavigate } from "react-router-dom";
import LoadVeriy from '../Component/Loading/LoadVeriy'

const Userpage = ({currentpage}) => {
    const [emailval, setEmailval] = useState("");
    const [testIdval, setTestIdval] = useState("");
    const [downloadUrl, setDownloadUrl] = useState([]);
    const [paymenturl, setPaymenturl] = useState([]);
    const [confiemres, setConfirmres] = useState(false)
    const [loading, setLoading] = useState(0)
    const [currentState, setCurrentState] = useState("")
    const [certifname, setCertifname] = useState("")
    let navigate = useNavigate();

    useEffect(()=>{
      setCurrentState("1");
      currentpage(1)
    },[])

    const handleSubmit = async (e)  =>{
      try{
          setLoading(1)
          e.preventDefault()
          let Objs = {
            email: emailval,
            studentID: testIdval
          }

          if(Objs.email !== "" && Objs.studentID !== ""){
            await axios.post(`https://sabre-ng-e-certificate.herokuapp.com/getCertificates`, Objs).then((response) => {
              if(response.status === 201){
                  console.log(response.data)
  
                  if(JSON.stringify(response.data).includes("certificates")){
                    setDownloadUrl(response.data.certificates.map((itemm) => (itemm.certificateURL)))
                    setConfirmres(true)
                    setCertifname(response.data.fullName)
                    // console.log(response.data.fullName)
                    setLoading(3)
                  }
                  else if(JSON.stringify(response.data).includes("paymentUrl")){
                    setPaymenturl(response.data.paymentUrl)
                    console.log(response.data.paymentUrl)
                    // window.open(paymenturl, "_self")
                    setLoading(5)
                  }
                  clearfields();
                }
                else{
                  console.log('error')
                }
            });
          } else {
            setLoading(4)
          }

        }
        catch(error){
          console.log('New error')
          setLoading(2)
          // setOverlaylog(2)
        }
    }

    const clearfields = () =>{
      setEmailval("");
      setTestIdval("");
    }

    const handleChanges = (e) =>{
      if(e.target.name === "email"){
        setEmailval(e.target.value)
      }
      if(e.target.name === "testid"){
        setTestIdval(e.target.value)
      }
    }

    const handleClose = () =>{
      setLoading("")
    }

    const handleCertClick = () =>{
      window.open(downloadUrl, "_blank")
      setConfirmres(false)
    }

    const handleDownloadClick = () =>{
      setConfirmres(false)
      
    }

  return (
    <div className='mainContain'>
      {loading === 1 ? <LoadVeriy type="spin" text = "Verifying Credentials..." /> : loading === 2 ? <Error closeOverlay={handleClose} message={"Email or ID Not Registerd!"} icon="2"/> : loading === 3 ? <Error closeOverlay={handleClose} message={"User Credentials Verified For "} icon="4" holdername={certifname} downloadnow={downloadUrl} Paynow={paymenturl} downlodclick={handleDownloadClick} downlod={true}/> : loading === 4 ? <Error closeOverlay={handleClose} message={"Email or ID Field Not Filled!"} icon="2"/> : loading === 5 ? <Error closeOverlay={handleClose} message={"User Credentials Verified "} icon="1" holdername={certifname} Paynow={paymenturl}/> : "" }
        <div className='bodyContiner'>
          <div className='iconContainer'><img src={Certif} alt="iconImage"  id='certificateIcon'/></div>
          <div className='iconContainer2'><span id="heading">Sabre E-Certificate Generation Portal</span></div>
          <form id="submitForm" onSubmit={handleSubmit}>
              <input onChange={handleChanges} name="email" value={emailval} type="email" placeholder="Enter Your Email Address" id="inputField" />
              <input onChange={handleChanges} name="testid" value={testIdval} type="text" placeholder="Enter Your Test ID" id="inputField" />
              <div className='buttnContainer'>
                <span id='submitContainer'>
                  <input value="VERIFY" type="submit" id="submitButn"/><FaSignInAlt id="submitIcon"/>
                </span>
                <span id='submitContainer'>
                  {confiemres ? <button id="submitButn_" onClick={handleCertClick}>DOWNLOAD</button> : <button id="submitButn_" disabled>DOWNLOAD</button>}
                  <FaCloudDownloadAlt id="submitIcon2" />
                </span>
              </div>
          </form>
      </div>
    </div>
  )
}

export default Userpage
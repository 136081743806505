import React, {useState} from 'react'
import '../Pages/style.css'
import { RiCloseCircleFill } from "react-icons/ri";
import { FaRegThumbsUp } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";
import { GiSandsOfTime} from "react-icons/gi";
import { FaDownload} from "react-icons/fa";
import { BsCloudUploadFill} from "react-icons/bs";
import { Link } from 'react-router-dom';

let counter = 1
const Error = ({closeOverlay, message, icon, isTimeout, holdername, Paynow, downloadnow, downlodclick, downlod}) => {
    // const [close, setClose] = useState(false)
    const Iconobj = {
      1 : <FaRegThumbsUp id='confIcon'/>,
      2 : <TiCancel id='errorIcon'/>,
      3 : <GiSandsOfTime id='errorIcon'/>,
      4 : <FaDownload id='confIcon'/>,
      5 : <BsCloudUploadFill id='confIcon'/>
    }
    const handleClose = () =>{
      // setClose(!close)
      closeOverlay(true)
    }

    const handlePayNow = () =>{
      window.open(Paynow, "_self")
    }

    const handleDownload = () =>{
      if(counter === 1){
        counter++
        downlodclick()
        window.open(downloadnow, "_self")
      }
    }

  return (
    <div className='errorContainer'>
        <div className='errorSubContain'>
            <div className='ClsiconContain'><RiCloseCircleFill onClick={handleClose} id='closeIcon'/></div>
            <div className='iconsContainer'>{Iconobj[icon]}</div>
            <span id="errorInfo">{message} {icon === "4" ? holdername : ""}</span>
            {downlod ? <button className='payment-butn' onClick={handleDownload}>Download Now</button> : "" }
            {icon === "1" ? <button className='payment-butn' onClick={handlePayNow}>Pay Now</button> : ""}
            {isTimeout && <button id='bnw-logout-button'><Link to="/login" className='bnw-logout'>Log Out</Link></button>}
        </div>
    </div>
  )
}

export default Error

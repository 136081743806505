import React,{useState, useEffect} from 'react'
import './style.css'
import './styles.css'
import FileSaver from 'file-saver'
import axios from 'axios'
import { useCookies } from 'react-cookie';
import Error from '../Component/Error'
import LoadVeriy from '../Component/Loading/LoadVeriy'
import PointCards from '../Component/Cards/pointCards';
import Spacing from '../Component/Cards/spacing'
import { FaHome } from 'react-icons/fa'
import ButtonPagenate from '../Component/ButtonPagenate/ButtonPagenate'
import CollectedcertJson from '../Component/Json/CollectedcertJson'

const Admin = ({currentpage}) => {
    const [file, setFile] = useState(null);
    const [csvFileError, setCsvFileError]=useState('');
    const [cookies, removeCookie] = useCookies(['Token']);
    const [loading, setLoading] = useState(0)
    const [tabledata, setTabledata] = useState({
      certCount: '',
      collected: [],
      collectedCount: '',
      uncollected: [],
      uncollectedCount: ''
    })
    const API = "https://certificate.sabrecwa.com/backend"
    const [pageNumber, setPageNumber] = useState(0);
    const [filtertab, setFiltertab] = useState(0)

    useEffect(()=>{
      currentpage(3)
      fetchTabledata()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const types = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']

    const changeHandler = async(e) =>{
        let selected = e.target.files[0]
        console.log(selected)

        if(selected && types.includes(selected.type)){
            try{
                setLoading(1)
                setFile(selected)
                setCsvFileError('')
                const formData = new FormData();
		            formData.append('studentFile', selected);

                var config = {
                    method: 'post',
                    url: `${API}/register`,
                    headers: { 
                     "Content-Type":"multipart/form-data",
                     "Authorization": `Bearer ${cookies.Token}`
                    },
                    data : formData
                };
                  
                await axios(config).then((response) => {
                    console.log(response.data)  
                    console.log("Correct")
                    if(response.data.message.includes("successfully")){
                      console.log("Correct Email")
                      console.log(response.data)
                      setLoading(3)
                    }
                })
              } catch(e){
                 console.log('New error')
                 console.log(e)
                 if(e.message.includes('401')){
                    // console.log(`Error `)
                    setLoading(2)
                  }
              }
        }else{
          setFile(null)
          setCsvFileError("Pls select a valid excel .xlsx file")
          setLoading(4)
        }
    }

    const handleBtnClick = () =>{
      FileSaver.saveAs(
      process.env.PUBLIC_URL + "/resources/Text.csv",
      "Template.csv"); 
    }

    const handleClose = () =>{
      setLoading(0)
    }

    const newPagenum = (num) =>{
      setPageNumber(num)
    }

    let tid = 1
    let ttid = 1

    const fetchTabledata = async () =>{
      try{
        var config = {
          method: 'GET',
          url: `${API}/certStatistics`
        };
  
        await axios(config).then((response) => {
        if(response.data.success === true){
          setTabledata({...response.data.data})
        }
        console.log(response.data)
        });
      } catch(e){
        if(e.message.includes('401')){
          // console.log(`Error `)
        } else{
          // setConfirmresponse(3)
          // setExpire()
        }
      }
    }

    const handleFilterClick = (e) =>{
      // console.log(e.target.id)
      if(e.target.id === "all"){
        setFiltertab(0)
      }

      if(e.target.id === "pend"){
        setFiltertab(1)
      }
    }

    const itemsPerpage = 6; //Number of items to be displayed per page 
    const pageCount = Math.ceil(tabledata.collected.length / itemsPerpage);
    const pageCountt = Math.ceil(tabledata.uncollected.length / itemsPerpage);
    const pagesVisited = pageNumber * itemsPerpage;
    
  return (
    <>
      <div className='adminContainer'>
        <div className='topbar-container'>
          <div className='firstdiv'>
            <FaHome id='home-icon' />
            <span>Dashboard</span>
          </div>

          <div className='top-div-container'>
            <div className='ecert-div-container'>
              <div onClick={handleBtnClick} type="submit" id="submitButn_" className='seconddiv'>Download</div>
              <input type="file" name="file" id="file" className="inputfile" onChange={changeHandler} hidden/>
              {cookies.Token === "undefined" ? "" : <label for="file" className='seconddiv'>Upload</label> }
            </div>

            <div className="label-output">
              {file && <div className="filename">{file.name}</div>}
            </div>
          </div>
        </div>
        <div className='class-row1'>
          <PointCards text={tabledata.collectedCount} ccolor="rgb(19, 0, 128)" icon= "1" title="Collected Certificates" baccolor="linear-gradient(90deg, rgba(0,70,106,0.3) 0%, rgba(1,131,198,0.3) 47%, rgba(178,229,255,0.3) 100%)"/>
          <PointCards text={tabledata.uncollectedCount} ccolor="purple" icon= "2" title="Uncollected Certificates" baccolor="linear-gradient(90deg, rgba(102,2,43,0.3) 0%, rgba(171,0,81,0.3) 47%, rgba(249,193,227,0.3) 100%)"/>
          <PointCards text={tabledata.certCount} ccolor="green" icon= "3" title="Total Certificate" baccolor="linear-gradient(90deg, rgba(0,122,22,0.3) 0%, rgba(110,255,136,0.3) 47%, rgba(190,255,202,0.3) 100%)"/>
        </div>

        <div className='class-row2'>
          <div className='bnw-filter-tab-cntainer'>
            <div id='all' className={filtertab === 0 ? 'bnw-fcc tab3' : 'bnw-fcc inactive'} onClick={handleFilterClick}>Collected</div>
            <div id='pend' className={filtertab === 1 ? 'bnw-fcc tab3' : ' bnw-fcc inactive'} onClick={handleFilterClick}>Uncollected</div>
          </div>
          {filtertab === 0 ?
          <div className='ecert-table-container'> 
            <table>
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Grade</th>
                  <th scope="col">Score</th>
                  <th scope="col">Course</th>
                  <th scope="col">Date</th>
                  <th scope="col">CertificateUrl</th>
                </tr>
              </thead>
              <tbody>
                {(tabledata.collected.length === 0 ? CollectedcertJson : tabledata.collected).reverse().slice(pagesVisited, pagesVisited + itemsPerpage).map((tdata) => (
                  <tr className='body-tab-row' key={tid++}>
                    <td data-label="Id">{tid}</td>
                    <td data-label="Grade">{tdata.certType}</td>
                    <td data-label="Score">{tdata.grade}</td>
                    <td data-label="Course">{tdata.course}</td>
                    <td data-label="Date">{tdata.date}</td>
                    <td data-label="CertificateUrl">{tdata.certificateURL}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className='bnw-table-pgnate-contain'>
              <ButtonPagenate 
              updatedPageNum={newPagenum}
              pageCount={pageCount}
              pageNumber={pageNumber}
              previousLabel="Prev"
              nextLabel="Next"/>
            </div>
          </div> : <div className='ecert-table-container'> 
            <table>
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Grade</th>
                  <th scope="col">Score</th>
                  <th scope="col">Course</th>
                  <th scope="col">Date</th>
                  <th scope="col">CertificateUrl</th>
                </tr>
              </thead>
              <tbody>
                {(tabledata.uncollected.length === 0 ? CollectedcertJson : tabledata.uncollected).reverse().slice(pagesVisited, pagesVisited + itemsPerpage).map((tdata) => (
                  <tr className='body-tab-row' key={ttid++}>
                    <td data-label="Id">{ttid}</td>
                    <td data-label="Grade">{tdata.certType}</td>
                    <td data-label="Score">{tdata.grade}</td>
                    <td data-label="Course">{tdata.course}</td>
                    <td data-label="Date">{tdata.date}</td>
                    <td data-label="CertificateUrl">{tdata.certificateURL}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className='bnw-table-pgnate-contain'>
              <ButtonPagenate 
              updatedPageNum={newPagenum}
              pageCount={pageCountt}
              pageNumber={pageNumber}
              previousLabel="Prev"
              nextLabel="Next"/>
            </div>
          </div>}
        </div>
            
      </div>

      {loading === 1 ? <LoadVeriy type="bars" text = "Uploading Record..."/> : loading === 2 ? <Error message={"Session Expired"} isTimeout={true} icon="3"/> : loading === 3 ? <Error closeOverlay={handleClose} message={"Upload Successful"} icon="5" /> : loading === 4? <Error closeOverlay={handleClose} message={csvFileError} icon="2" /> : ""}
    </>
  )
}

export default Admin
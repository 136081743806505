const CollectedcertJson = [
    {
        id: 'null',
        certType: 'null',
        score: 'null',
        course: 'null',
        date: 'null',
        certificateURL: 'null' 
    }
]

export default CollectedcertJson
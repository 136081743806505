import React, {useState,useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import { useCookies } from 'react-cookie';
import Error from '../Component/Error'
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { RiLock2Fill } from "react-icons/ri";
import './page.css'
import PasswordInputField from '../Component/Passwordverify/password';
import ConfirmPasswordInputField from '../Component/Passwordverify/Confirmpassword';
import LoadVeriy from '../Component/Loading/LoadVeriy';

export default function Loginpg({currentpage}) {
  const [loading, setLoading] = useState(2)
  const [currentState, setCurrentState] = useState("")
  const [loaderror, setLoaderror] = useState(0)
  const [visible, setVisible] = useState(true)
  const navigate = useNavigate()
  const [cookies, setCookie] = useCookies(['Token']);
  const [emailvalue, setEmailvalue] = useState()

  const [passwordError, setPasswordErr] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordInput, setPasswordInput]= useState({
      password:'',
      confirmPassword:'',
      otp: ''
  })

  const [emailfield, setEmailfield] = useState('')
  const [passwordfield, setPasswordfield] = useState('')

  const [loadVerify, setLoadVerify] = useState(0)
  const [sendemail, setSendemail] = useState(0)
  const API = "https://certificate.sabrecwa.com/backend"

  useEffect(()=>{
    setCurrentState("0");
    setCookie('Token', "undefined", { path: '/'});
    currentpage(2)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  ///********** Handle Submit Button ********///
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoaderror(1)
    try{
      var config = {
        method: 'POST',
        url: `${API}/login`,
        data: {
          email: emailfield,
          password: passwordfield,
        }
      };
      console.log(config)
      if(config.data.email !== "" && config.data.password !== ""){
        await axios(config).then((response) => {
          if(response.data.code === 200){
            setLoaderror(1)
            const objct = {
              status: response.data.code
            }
            navigate('/documentupload', {state:objct})
            setCookie('Token', response.data.token, { path: '/'});      // Set Token To the Cookie Value and make it accessible to all routes
          }
        });
      } else {
        console.log("Mine")
        setLoaderror(2)
      }

      }catch(e){
        console.log(`Error ${e.status}`)
        // setLoading(2)
        setLoaderror(2)
      }
  };


  ///******* Handle Reset Submit *******////
  const handleResetSubmit = async (e) =>{
    try{
        e.preventDefault();
        setLoaderror(1)

        // If Confirm password Error is not returning any error and Confirm password Field is not empty, that suggest a password match
        if(confirmPasswordError === "" && passwordError === "" && passwordInput.confirmPassword !== "" && passwordInput.password !== "" && passwordInput.otp !== ""){
          const obj = {
            passwrd: passwordInput.confirmPassword,
            otp: passwordInput.otp,
          }

          await axios.post(`${API}/login`, obj).then((response) => {
          if(response.data.code === 200){
              // console.log(response.data);
              setLoaderror(1)
              const objct = {
                role: response.data.role,
                status: response.data.code,
              }
              navigate('/doashboard', {state:objct})
              setCookie('Token', response.data.token, { path: '/'});
          } else {
            setLoaderror(2)
          }});
        }

        else{
          // console.log("Check All Fields")
          setLoading(4)
        }

      }catch(e){
        setLoading(5)
        setLoaderror(true)
      }
  }


  ///******** Handle Fields Onchange *******///
  const handleLoginChange = (e) =>{
    if(e.target.name === 'email'){
      setEmailfield(e.target.value)
      // console.log(e.target.value)
    }
  
    if(e.target.name === 'password'){
      setPasswordfield(e.target.value)
      // console.log(e.target.value)
    }
 }


   ///********** Verify That OTP is confirmed ********///
   const Bnwcnpotpfield = async (e) =>{
    setPasswordInput({otp: e.target.value})
    const obj = passwordInput.otp

    // If the User Inputs OTP of length 6
    if(e.target.value.length === 6){
      setLoadVerify(1)                              // Loading Component
      await axios.post(`${API}/verifyotp`, obj).then((response) => {
        if(response.data.code === 200){
          // console.log(response.data);
          setLoadVerify(2)                          // OTP Verification Approved
        } else {
          setLoadVerify(3)                          // OTP Verification Error
        }
      });
    }
  }


  ///****** Send OTP to Email Address ******///
  const handleOTPSubmit = async (e) =>{
    e.preventDefault();
    setSendemail(1)
    const obj = emailvalue
    await axios.post(`${API}/emailsent`, obj).then((response) => {
      if(response.data.code === 200){
        // console.log(response.data);
        setSendemail(2)                           // Email Sent
        setLoading(5)                             // Move To The Next Page
      } else {
        setSendemail(3)                           // Unable to send Email
      }
    });
  }

  //// ****** CLOSE All Error/Confirmation Overlay ****** ///
  const handleClose = () =>{
    setLoaderror(0)
    setLoadVerify(0)
    setSendemail(0)
  }

  const handleVisIcon = () =>{
    setVisible(!visible)
  }

  const handleGoBack = () =>{
    setLoading(2)
  }

  const handleForgotpass = () =>{
    setLoading(3)
  }

  const handlePasswordChange =(evnt)=>{
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    const NewPasswordInput = {...passwordInput,[passwordInputFieldName]:passwordInputValue}
    setPasswordInput(NewPasswordInput);
    // console.log(`setPass: ${JSON.stringify(NewPasswordInput)}`)
    
  }

  const handleValidation= (evnt)=>{
      const passwordInputValue = evnt.target.value.trim();
      const passwordInputFieldName = evnt.target.name;
        //for password 
        if(passwordInputFieldName==='password'){
          const uppercaseRegExp   = /(?=.*?[A-Z])/;
          const lowercaseRegExp   = /(?=.*?[a-z])/;
          const digitsRegExp      = /(?=.*?[0-9])/;
          const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
          const minLengthRegExp   = /.{8,}/;
          const passwordLength =      passwordInputValue.length;
          const uppercasePassword =   uppercaseRegExp.test(passwordInputValue);
          const lowercasePassword =   lowercaseRegExp.test(passwordInputValue);
          const digitsPassword =      digitsRegExp.test(passwordInputValue);
          const specialCharPassword = specialCharRegExp.test(passwordInputValue);
          const minLengthPassword =   minLengthRegExp.test(passwordInputValue);
          let errMsg ="";
          if(passwordLength===0){
                  errMsg="Password is empty";
          }else if(!uppercasePassword){
                  errMsg="At least one Uppercase";
          }else if(!lowercasePassword){
                  errMsg="At least one Lowercase";
          }else if(!digitsPassword){
                  errMsg="At least one digit";
          }else if(!specialCharPassword){
                  errMsg="At least one Special Characters";
          }else if(!minLengthPassword){
                  errMsg="At least minumum 8 characters";
          }else{
              errMsg="";
          }
          setPasswordErr(errMsg);
      }

      // for confirm password
      if(passwordInputFieldName=== "confirmPassword" || (passwordInputFieldName==="password" && passwordInput.confirmPassword.length>0) ){  
        if(passwordInput.confirmPassword!==passwordInput.password)
          {
            setConfirmPasswordError("Confirm password is not matched");
          }else{
            setConfirmPasswordError("");
          }
      }
  }

  const handlebnwemailconfm = (e) =>{
    setEmailvalue(e.target.value)
    // console.log(e.target.value)
  } 

  return (
    <div className='bnw-login-contain'>
      {loading === 2 ? <div className='signinContain'>
        {loaderror === 1 ? <LoadVeriy type="spin" text = "Loading..."/> : loaderror === 2 ? <Error closeOverlay={handleClose} message={"Invalid Username or Password"} icon="2"/> : ""}
          <form className='bnw-login-body' onSubmit={handleSubmit}>
            <div className='bnw-lock-icon-container'><RiLock2Fill id='bnw-lock-icon'/></div>
            <span>SIGN IN</span>
            <input className='bnw-ligin-email' name='email' placeholder='Email' value={emailfield} type="email" onChange={handleLoginChange} />
            <div className='bnw-ligin-email-container'>
              <input className='bnw-ligin-password' name='password' placeholder='Password' value={passwordfield} type={visible ? "password":"text"} onChange={handleLoginChange}/>
              { visible ? <MdOutlineVisibility id='visib-icon' onClick={handleVisIcon}/> : <MdOutlineVisibilityOff id='visib-icon' onClick={handleVisIcon}/> }
            </div>
            <div className='forgotpass-cont'><span onClick={handleForgotpass}>Forgot Password?</span></div>
            <input className='bnw-login-submit' type="submit" value="SIGN IN" />
          </form>

      </div> : loading === 3 ? <div className='signinContain'>
      {sendemail === 1 ? <LoadVeriy type="spin" text = "Sending Confirmation Email"/> : sendemail === 2 ? <Error closeOverlay={handleClose} message={"Email Sent"} icon="1"/> : sendemail === 3 ? <Error closeOverlay={handleClose} message={"Connection Error"} icon="2"/> : ""}
        <form className='bnw-login-body' onSubmit={handleOTPSubmit}>
          <div className='bnw-lock-icon-container'><RiLock2Fill id='bnw-lock-icon'/></div>
          <span>RESET PASSWORD</span>
          <div className='resetpass-bnw'>
            <span>Enter the Email Asscoiated with your account and we'll send an Email OTP to Complete your password reset</span>
          </div>
          <input className='bnw-ligin-email' placeholder='Email Address' value={emailvalue} onChange={handlebnwemailconfm} type="email"  />
          <div className='forgotpass-cont'><span onClick={handleGoBack}>Back</span></div>
          <input className='bnw-login-submit' type="submit" value="Generate 2FA OTP" />
        </form>

      </div> : <div className='signinContain'>

        {loadVerify === 1 ? <LoadVeriy type="spin" text = "Verifying OTP"/> : loadVerify === 2 ? <Error closeOverlay={handleClose} message={"OTP Approved"} icon="1"/> : loadVerify === 3 ? <Error closeOverlay={handleClose} message={"Invalid OTP"} icon="2"/> : ""}
        <form className='bnw-login-body' onSubmit={handleResetSubmit}>
          <div className='bnw-lock-icon-container'><RiLock2Fill id='bnw-lock-icon'/></div>
          <span>Create New Password</span>
          <input className='bnw-ligin-email' placeholder='2FA-Code' value={passwordInput.otp} type="text" onChange={Bnwcnpotpfield} />

          <div className='bnw-ligin-email-container'>
            <PasswordInputField 
            handlePasswordChange={handlePasswordChange} 
            handleValidation={handleValidation} 
            passwordValue={passwordInput.password} 
            passwordError={passwordError}/>
          </div>

          <div className='bnw-ligin-email-container'>
  
            <ConfirmPasswordInputField 
            handlePasswordChange={handlePasswordChange} 
            handleValidation={handleValidation} 
            confirmPasswordValue={passwordInput.confirmPassword} 
            confirmPasswordError={confirmPasswordError}/>
          </div>
          <div className='forgotpass-cont'><span onClick={handleGoBack}>Back To Home</span></div>
          <input className='bnw-login-submit' type="submit" value="Confirm Password Change" />
        </form>
      </div> }
    </div>  
  );
}
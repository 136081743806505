import './App.css';
import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Admin from './Pages/Admin';
import Userpage from './Pages/Userpage';
import Loginpg from './Pages/Login';
import PrivateRoute from './Protected';
import Navbar from './Component/Navbar';
import Footer from './Pages/Footer';

const App = () =>{
  const [page, setPage] = useState(0)
  const setcurrentpg = (incompg) =>{
    console.log(incompg)
    setPage(incompg)
  }
  
  return (
    <Router>
      <Navbar currentpage={page}/>
      <div className="appcontainer">
          <Routes>
            <Route exact path="/" element={<Userpage currentpage={setcurrentpg}/>} /> 
            <Route path="/login" element={<Loginpg currentpage={setcurrentpg}/>} />
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/documentupload" element={<Admin currentpage={setcurrentpg}/>}/>
            </Route>
          </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;

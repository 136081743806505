import React, {useState} from 'react'
import './psswrd.css'
import '../Cards/addoverlay.css'
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";

const ConfirmPasswordInputField = ({handleValidation, handlePasswordChange, confirmPasswordValue, confirmPasswordError}) =>{
    const [visible, setVisible] = useState(true)

    const handleVisIcon = () =>{
        setVisible(!visible)
    }

    return (
            <div className="bnwpswrd-field_">
                <input  id="bnw-firname-box_" placeholder="Confirm Password" type={visible ? "password":"text"} value={confirmPasswordValue}  onChange={handlePasswordChange} onKeyUp={handleValidation} name="confirmPassword" className="form-control" />
                <p className="text-danger">{confirmPasswordError}</p>
                { visible ? <MdOutlineVisibility id='visib-icon' onClick={handleVisIcon}/> : <MdOutlineVisibilityOff id='visib-icon' onClick={handleVisIcon}/> }
            </div>
    )
}

export default ConfirmPasswordInputField;
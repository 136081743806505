import React from 'react'
import "../Pages/style.css"

const Footer = () => {
  return (
    <div className='footerContainer'>
        <span id="footer-text">
            Copyright &#169; <b>2022 Sabre Travel Network</b>. All rights
            Reserved
      </span>
    </div>
  )
}

export default Footer